export default class SearchKeyWord {
  value: string;

  constructor(value?: string) {
    this.value = (value === undefined ? '' : value);
  }

  isEmpty(): boolean {
    return this.value === '';
  }
}
