import { AxiosPromise } from 'axios';
import PostSearchConditions from '@/model/posts/search/PostSearchConditions';

import PostsAndCommentHeadline from '@/model/posts/PostsAndCommentHeadline';
import PostId from '@/model/posts/PostId';
import axios from '../AxiosBase';

export default {

  searchPosts(postSearchConditions:PostSearchConditions, limit: number, pageNumber: number):AxiosPromise<{posts:PostsAndCommentHeadline[], dataLength: number}> {
    return axios.post(`posts/search?limit=${limit}&page=${pageNumber}`, postSearchConditions);
  },
  searchSendPosts(postSearchConditions:PostSearchConditions, limit: number, pageNumber: number):AxiosPromise<{posts:PostsAndCommentHeadline[], dataLength: number}> {
    return axios.post(`posts/findSend?limit=${limit}&page=${pageNumber}`, postSearchConditions);
  },
  searchStatus(postId:PostId):AxiosPromise<any> {
    return axios.get(`posts/searchStatus/${postId.value}`);
  }
};
