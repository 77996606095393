import MonthReportID from '../monthReport/MonthReportID';
import TypeDateTime from '../type/TypeDateTime';
import Priv from '../user/Priv';
import UserID from '../user/UserID';
import UserName from '../user/UserName';
import CommentHeadline from './CommentHeadline';
import DataType from './DataType';
import PostBody from './PostBody';
import PostHeadline from './PostHeadline';
import PostId from './PostId';
import PostReader from './PostReader';
import PostStatus from './PostStatus';
import PostTitle from './PostTitle';
import PostViewerList from './PostViewerList';

export default class PostsAndCommentHeadline {
  /** 投稿 */
  body: PostBody;

  /** 投稿ID */
  postId: PostId;

  monthReportId: MonthReportID;

  monthReportUserId: UserID;

  /** 投稿区分 */
  dataType: DataType;

  /** 元投稿タイトル */
  postTitle: PostTitle;

  /** 投稿者ID */
  registerId: UserID;

  // /** 投稿者権限 */
  // registerPriv: Priv;

  /** 投稿者名 */
  registerName: UserName;

  /** 公開日時 */
  openDate: TypeDateTime;

  /** ステータス */
  status: PostStatus;

  // /** 元投稿 */
  // originPost: PostHeadline;

  // /** 親コメント */
  // parentComment: CommentHeadline;

  // /** 閲覧可能者 */
  // viewers: PostViewerList;

  // /** 既読者 */
  // readers: PostReader;

  /** Key(v-for用) */
  key: string;

  /** 閲覧日時 */
  readDate: string;

  /** 既読人数 */
  readedNum: number;

  /** 閲覧可能人数 */
  viewersNum: number;

  constructor(
    body?: PostBody,
    postId?: PostId,
    monthReportId?: MonthReportID,
    monthReportUserId?: UserID,
    dataType?: DataType,
    postTitle?: PostTitle,
    registerId?: UserID,
    // registerPriv?: Priv,
    registerName?: UserName,
    openDate?: TypeDateTime,
    status?: PostStatus,
    // originPost?: PostHeadline,
    // parentComment?: CommentHeadline,
    // viewers?: PostViewerList,
    // readers?: PostReader,
    key?: string,
    readDate?: string,
    readedNum?: number,
    viewersNum?: number
  ) {
    this.body = (body === undefined ? new PostBody() : body);
    this.postId = (postId === undefined ? new PostId() : postId);
    this.monthReportId = (monthReportId === undefined ? new MonthReportID() : monthReportId);
    this.monthReportUserId = (monthReportUserId === undefined ? new UserID() : monthReportUserId);
    this.dataType = (dataType === undefined ? new DataType() : dataType);
    this.postTitle = (postTitle === undefined ? new PostTitle() : postTitle);
    this.registerId = (registerId === undefined ? new UserID() : registerId);
    // this.registerPriv = (registerPriv === undefined ? new Priv() : registerPriv);
    this.registerName = (registerName === undefined ? new UserName() : registerName);
    this.openDate = (openDate === undefined ? new TypeDateTime() : openDate);
    this.status = (status === undefined ? new PostStatus() : status);
    // this.originPost = (originPost === undefined ? new PostHeadline() : originPost);
    // this.parentComment = (parentComment === undefined ? new CommentHeadline() : parentComment);
    // this.viewers = (viewers === undefined ? new PostViewerList() : viewers);
    // this.readers = (readers === undefined ? new PostReader() : readers);
    this.key = key === undefined ? '' : key;
    this.readDate = readDate === undefined ? '' : readDate;
    this.readedNum = readedNum === undefined ? 0 : readedNum;
    this.viewersNum = viewersNum === undefined ? 0 : viewersNum;
  }
  static cloneList(source: PostsAndCommentHeadline[]): PostsAndCommentHeadline[] {
    return source.map((s) => PostsAndCommentHeadline.clone(s));
  }
  static clone(source: PostsAndCommentHeadline): PostsAndCommentHeadline {
    const result = new PostsAndCommentHeadline();
    result.body = PostBody.clone(source.body);
    result.postId = PostId.clone(source.postId);
    result.monthReportId = MonthReportID.clone(source.monthReportId);
    result.monthReportUserId = UserID.clone(source.monthReportUserId);
    result.dataType = DataType.clone(source.dataType);
    result.postTitle = PostTitle.clone(source.postTitle);
    result.registerId = UserID.clone(source.registerId);
    // result.registerPriv = Priv.clone(source.registerPriv);
    result.registerName = UserName.clone(source.registerName);
    result.openDate = TypeDateTime.clone(source.openDate);
    result.status = PostStatus.clone(source.status);
    // result.originPost = PostHeadline.clone(source.originPost);
    // result.parentComment = CommentHeadline.clone(source.parentComment);
    // result.viewers = PostViewerList.clone(source.viewers);
    // result.readers = PostReader.clone(source.readers);
    result.key = source.key;
    result.readDate = source.readDate;
    result.readedNum = source.readedNum;
    result.viewersNum = source.viewersNum;
    return result;
  }
}
