import { render, staticRenderFns } from "./PostsViewer.vue?vue&type=template&id=1d758c70&scoped=true"
import script from "./PostsViewer.vue?vue&type=script&lang=ts"
export * from "./PostsViewer.vue?vue&type=script&lang=ts"
import style0 from "./PostsViewer.vue?vue&type=style&index=0&id=1d758c70&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d758c70",
  null
  
)

export default component.exports