
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router';
import PostType from '@/model/posts/search/PostType';
import PostSearchConditions from '@/model/posts/search/PostSearchConditions';
import PostsAndCommentApi from '@/module/api/PostsAndCommentApi';
import PostsAndCommentHeadline from '@/model/posts/PostsAndCommentHeadline';
import PostViewerBody from '@/components/post/PostViewerBody.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave'
]);
@Component({
  components: {
    PostViewerBody
  }
})
export default class PostsViewer extends Vue {
  @Getter('isActiveUser') isActiveUser!:boolean;
  @Getter('isAdminGroup')isAdminGroup!: boolean;
  @Getter('pageCache') getPageCache!: (key:string)=> any;
  @Mutation('commitPageCache') commitPageCache!: any;

  searchConditions = new PostSearchConditions();
  posts:PostsAndCommentHeadline[] = [];
  /** 一度に表示する件数 */
  limit: number = 25;
  pageNumber: number = 1;
  dataLength: number = 0;
  selectTypeList: string[] = [];
  displayPath: string = this.$route.path;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<PostsViewer>): void {
    next((vm: PostsViewer) => {
      vm.rememberPageView();
    });
  }

  rememberPageView(): void {
    const cache = this.getPageCache(this.displayPath);
    if (cache) {
      this.posts = cache.posts;
      this.searchConditions = cache.conditions;
      this.limit = cache.limit;
      this.pageNumber = cache.pageNumber;
      this.dataLength = cache.dataLength;
      this.setTypeParamForDisplay();
    } else {
      this.clear();
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<PostsViewer>): void {
    this.commitPageCache(
      {
        pageName: this.displayPath,
        value: {
          posts: this.posts,
          conditions: this.searchConditions,
          limit: this.limit,
          pageNumber: this.pageNumber,
          dataLength: this.dataLength
        }
      }
    );
    next();
  }

  mounted():void {
    this.$root.$on('clickLogo', () => {
      this.clear();
    });
    this.getData();
  }

  destroyed():void{
    this.$root.$off('clickLogo');
  }

  initialize():void{
    this.searchConditions.readed.value = '';
    this.selectTypeList = ['info', 'limit', 'vacation', 'signup', 'monthreport'];
    this.searchConditions.sendOrReceive.value = '';
    this.searchConditions.target.value = ['title', 'register', 'message', 'comment'];
    this.searchConditions.keyWord.value = '';

    this.limit = 25;
    this.pageNumber = 1;
  }

  search():void {
    this.pageNumber = 1;
    this.getData();
  }
  getPage(page: number):void {
    this.pageNumber = page;
    this.getData();
  }

  // 表示する選択可能ページ数（最大７）
  showPages(pages:number):number {
    if (pages > 7) return 7;
    return pages;
  }

  getPageNumber(idx:number, pages:number, pageNumber:number):number {
    if (pages <= 7) return idx;
    if (idx === 1) {
      if (pageNumber === 1) return -1;
      return 1;
    }
    if (idx === 7) {
      if (pageNumber === pages) return -1;
      return pages;
    }
    if (idx === 4) return pageNumber;

    if (idx < 4) {
      const p = pageNumber - (4 - idx);
      if (p <= 1) return -1;
      return p;
      // if (pageNumber > 4) return pageNumber - (4 - idx);
    }
    // if (idx > 4) {
    const p = pageNumber + (idx - 4);
    if (p >= pages) return -1;
    return p;
    // }
  }

  showPage(idx: number, pages:number, pageNumber:number):boolean {
    if (idx === 1) return true;
    if (idx === pages) return true;

    if ((pageNumber - 3 <= idx) && (idx <= pageNumber + 3)) return true;
    return false;
  }

  getData():void {
    this.setTypeParam();
    PostsAndCommentApi.searchPosts(this.searchConditions, this.limit, this.pageNumber).then((res) => {
      this.posts = PostsAndCommentHeadline.cloneList(res.data.posts);
      this.dataLength = res.data.dataLength;
    });
  }

  get topRow(): number {
    if (this.dataLength === 0) return 0;
    return this.limit * (this.pageNumber - 1) + 1;
  }
  get endRow(): number {
    const end = this.limit * this.pageNumber;
    if (this.dataLength < end) return this.dataLength;
    return end;
  }
  get pages(): number {
    return Math.ceil(this.dataLength / this.limit);
  }
  clear():void{
    this.initialize();
    // 検索実行
    this.getData();
  }
  setTypeParamForDisplay():void {
    this.selectTypeList = [];
    this.searchConditions.type.value.forEach((type) => {
      this.selectTypeList.push(type.value);
    });
  }
  setTypeParam():void{
    this.searchConditions.type.value = [];
    this.selectTypeList.forEach((type) => {
      this.searchConditions.type.value.push(new PostType(type));
    });
  }
  isCanSearch():boolean {
    if (this.selectTypeList.length > 0 && this.searchConditions.isRequireTarget()) {
      return true;
    }
    return false;
  }
}

