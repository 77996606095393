
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import PostsAndCommentHeadline from '@/model/posts/PostsAndCommentHeadline';
import { COMMENT } from '@/model/posts/DataType';
import UserBaseInfo from '@/model/user/UserBaseInfo';

@Component({})
export default class PostViewerBody extends Vue {
  @State('loginUser') loginUser!: UserBaseInfo;
  @Prop({ default: [] }) posts?: PostsAndCommentHeadline[];
  @Getter('isAdminGroup') isAdminGroup!: boolean;

  readerLink(pPost: PostsAndCommentHeadline): string {
    const hasLimit = pPost.body.message.limitDate.value ? 'true' : 'false';
    return `/readedUsers/${pPost.postId.value}/${hasLimit}`;
  }
  showViewer(p: PostsAndCommentHeadline): boolean {
    if (p.dataType.value === COMMENT.value) return false;
    // if (p.status.value === DRAFT.value) return false;
    return true;
  }
  monthlyReport(post: PostsAndCommentHeadline): string {
    if (this.loginUser.priv.isAdminGroup) {
      return `/monthlyReportList/${post.monthReportUserId.value}#report-${post.monthReportId.value}`;
    }
    return `/monthlyReportList#report-${post.monthReportId.value}`;
  }
  information(post: PostsAndCommentHeadline): string {
    if (post.postId.value !== post.body.postId.value) {
      // コメントの場合
      return `/information/${post.postId.value}#comment-${post.body.postId.value}`;
    }
    return `/information/${post.postId.value}`;
  }
}
