export default class MonthReportID {
value: string;

constructor(value?: string) {
  this.value = (value === undefined ? '' : value);
}
static clone(source: MonthReportID): MonthReportID {
  return new MonthReportID(source.value);
}
}
