import Status from '@/model/user/Status';
import PostStatus from '../PostStatus';
import PostReaded from './PostReaded';
import PostTypeList from './PostTypeList';
import SearchKeyWord from './SearchKeyWord';
import SearchTarget from './SearchTarget';
import SendOrReceive from './SendOrReceive';

/**
 * 投稿一覧検索条件
 */
export default class PostSearchConditions {
  /** チェック(未読/既読) */
  readed: PostReaded;

  /** 投稿区分 */
  type: PostTypeList;

  /** 送信/受信 */
  sendOrReceive: SendOrReceive;

  /** 検索範囲 */
  target: SearchTarget;

  /** キーワード */
  keyWord: SearchKeyWord;

  status: PostStatus;
  constructor(
    readed?: PostReaded,
    type?: PostTypeList,
    sendOrReceive?: SendOrReceive,
    target?: SearchTarget,
    keyWord?: SearchKeyWord,
    status?: PostStatus
  ) {
    this.readed = (readed === undefined ? new PostReaded() : readed);
    this.type = (type === undefined ? new PostTypeList() : type);
    this.sendOrReceive = (sendOrReceive === undefined ? new SendOrReceive() : sendOrReceive);
    this.target = (target === undefined ? new SearchTarget() : target);
    this.keyWord = (keyWord === undefined ? new SearchKeyWord() : keyWord);
    this.status = (status === undefined ? new PostStatus() : status);
  }

  /**
   * キーワード入力時の検索範囲選択必須チェック
   * @returns true:OK false:NG
   */
  isRequireTarget(): boolean {
    if (this.keyWord.isEmpty() === false) {
      if (this.target.value.length === 0) {
        return false;
      }
    }
    return true;
  }
}
